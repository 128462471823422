import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, TimeScale, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { AuthContext } from '../context/AuthContext';
import { Card, Button, Alert } from 'react-bootstrap';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, TimeScale, Title, Tooltip, Legend);

const socket = io(process.env.REACT_APP_API_URL);

const DeviceList = () => {
  const [devices, setDevices] = useState([]);
  const [error, setError] = useState('');
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/devices', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setDevices(response.data.devices);
      } catch (err) {
        setError(err.response?.data?.error || 'Failed to fetch devices');
      }
    };
    fetchDevices();

    socket.emit('authenticate', token);

    socket.on('newDeviceData', (updatedDevice) => {
      setDevices((prev) =>
        prev.map((device) =>
          device._id === updatedDevice._id ? updatedDevice : device
        )
      );
    });

    return () => socket.off('newDeviceData');
  }, [token]);

  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div>
      {devices.length === 0 ? (
        <p>No devices yet. Register a device and publish some data!</p>
      ) : (
        <div className="row">
          {devices.map((device) => (
            <div key={device._id} className="col-md-4 mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title
                    className="text-primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/app/device/${device.deviceId}`)}
                  >
                    {device.deviceName} ({device.deviceId})
                  </Card.Title>
                  {Object.entries(device.data).map(([key, value]) => (
                    <p key={key}>
                      {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
                    </p>
                  ))}
                  <small className="text-muted">
                    {new Date(device.timestamp).toLocaleString()}
                  </small>
                  <Button
                    variant="outline-primary"
                    className="mt-3 w-100"
                    onClick={() => navigate(`/app/devices/edit/${device.deviceId}`, { state: { sensors: device.sensors || [] } })}
                  >
                    Edit Device
                  </Button>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DeviceList;