import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [userFirstName, setUserFirstName] = useState(localStorage.getItem('firstName') || '');

  const login = (newToken, firstName) => {
    setToken(newToken);
    setUserFirstName(firstName);
    localStorage.setItem('token', newToken);
    localStorage.setItem('firstName', firstName);
  };

  const logout = () => {
    setToken('');
    setUserFirstName('');
    localStorage.removeItem('token');
    localStorage.removeItem('firstName');
  };

  return (
    <AuthContext.Provider value={{ token, userFirstName, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};