import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const DeviceRegister = () => {
  const [deviceName, setDeviceName] = useState('');
  const [sensors, setSensors] = useState([]);
  const [currentSensorType, setCurrentSensorType] = useState('');
  const [currentSensorPin, setCurrentSensorPin] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showBlockly, setShowBlockly] = useState(false);
  const [deviceId, setDeviceId] = useState('');
  const { token, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/app');
    }
  }, [token, navigate]);

  useEffect(() => {
    if (showBlockly) {
      navigate(`/app/devices/edit/${deviceId}`, { state: { sensors } });
    }
  }, [showBlockly, deviceId, sensors, navigate]);

  const addSensor = () => {
    if (currentSensorType && currentSensorPin) {
      setSensors([...sensors, { sensorType: currentSensorType, pin: currentSensorPin }]);
      setCurrentSensorType('');
      setCurrentSensorPin('');
    }
  };

  const removeSensor = (index) => {
    setSensors(sensors.filter((_, i) => i !== index));
  };

  const handleRegisterDevice = async (e) => {
    e.preventDefault();
    try {
      if (sensors.length === 0) {
        setError('Please add at least one sensor');
        return;
      }
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/api/devices/register',
        { deviceName, sensors },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDeviceId(response.data.deviceId);
      setSuccess('Device registered successfully!');
      setError('');
      setShowBlockly(true);
    } catch (err) {
      setError(err.response?.data?.error || 'Device registration failed');
      setSuccess('');
    }
  };

  return (
    <div className="devices-container">
      <header>
        <h1>Register Device</h1>
        <button className="logout-btn" onClick={() => logout()}>
          Logout
        </button>
      </header>
      <div className="register-device-form">
        <form onSubmit={handleRegisterDevice}>
          <div className="input-group">
            <label>Device Name</label>
            <input
              type="text"
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              placeholder="e.g., Living Room Sensor"
              required
            />
          </div>
          <div className="input-group">
            <label>Add Sensor</label>
            <select
              value={currentSensorType}
              onChange={(e) => setCurrentSensorType(e.target.value)}
            >
              <option value="">Select sensor type</option>
              <option value="temperature">Temperature</option>
              <option value="humidity">Humidity</option>
              <option value="soil_moisture">Soil Moisture</option>
            </select>
            <input
              type="text"
              value={currentSensorPin}
              onChange={(e) => setCurrentSensorPin(e.target.value)}
              placeholder="Pin (e.g., D2)"
            />
            <button type="button" onClick={addSensor}>Add Sensor</button>
          </div>
          {sensors.length > 0 && (
            <div>
              <h4>Sensors:</h4>
              <ul>
                {sensors.map((sensor, index) => (
                  <li key={index}>
                    {sensor.sensorType} on pin {sensor.pin}
                    <button type="button" onClick={() => removeSensor(index)}>Remove</button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}
          <button type="submit">Register</button>
        </form>
        <p className="toggle-link" onClick={() => navigate('/app/dashboard')}>
          Back to Dashboard
        </p>
      </div>
    </div>
  );
};

export default DeviceRegister;