import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import LandingPage from './pages/LandingPage';
import AboutPage from './pages/AboutPage'; 
import ForumPage from './pages/ForumPage';
import Login from './pages/Login';
import DeviceRegister from './pages/DeviceRegister';
import Dashboard from './pages/Dashboard';
import DeviceDashboard from './pages/DeviceDashboard';
import BlocklyEditor from './components/BlocklyEditor';
import './styles/custom.scss';
import './App.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Landing Page and About Page as top-level routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          
          {/* Existing app routes under /app */}
          <Route path="/app">
            <Route index element={<Login />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="devices/register" element={<DeviceRegister />} />
            <Route path="device/:deviceId" element={<DeviceDashboard />} />
            <Route path="devices/edit/:deviceId" element={<BlocklyEditor />} />
            <Route path="forum" element={<ForumPage />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;