import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavBar from '../components/NavBar';

const AboutPage = () => {
  return (
    <div>
      {/* Header */}
      <NavBar/>
      {/* About Section */}
      <section className="py-5">
        <Container>
          <Row>
            <Col>
              <h1 className="mb-4 text-center">About Garden Sync</h1>
              <p className="lead text-center mb-5">
                Empowering gardeners with smart technology to nurture their green spaces.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={8} className="mx-auto">
              <h2 className="mb-3">The Founder</h2>
              <p>
                Corbin Lamplot grew up on a farm in northeast Nebraska, where his passion for agriculture and wonder for technology created the first spark for Garden Sync. Having a strong interest in technology led Corbin to the University of Nebraska-Linconl where he earned his B.S. in Computer Science in 2023. During his time in school, he learned about the enless possibilities that microcontroller technology could provide to everyday people, which inspired him to use his experiences to develop his first auto watering sytem. 
              </p>
              <p>
                The first auto watering system was built in his and his wife's small appartment in downtown Lincoln while he was attending the Univeristy. With minimal experince to electrical and electronics engineering, much of the circuitry would be considered an insult to professional engineers, but it was early on in his Computer Science journey and if it worked, that was good enough at the time. Using supplies from Menards, electrical components ordered from Amazon, an old cat watering fountain pump, and some custom components printed from his 3D printer, the auto watering sytem was up and running surprisingly well. 
              </p>
              <p>
              Having a busy workload and striving to be on the Dean's list, Corbin could be worry free about his plants since he knew they were getting the optimal amount of water every day. The system proved to function well as the catnip he grew for his cat thrived beyond expectation. 
              </p>
              <h2 className="mt-5 mb-3">Our Mission</h2>
              <p>
                At Garden Sync, our mission is to empower gardeners with the tools they need to nurture their plants with precision and ease. We believe that technology can transform the way we care for our green spaces, making gardening accessible and enjoyable for everyone, from beginners to seasoned experts.
              </p>
              <h2 className="mt-5 mb-3">Join Us</h2>
              <p>
                Whether you’re a tech-savvy gardener or just starting out, Garden Sync is here to help you grow. We are currently in alpha testing and extensive development, but will start looking for beta testers soon. We're looking forward for you to joining our community, sharing your experiences in our forum, and cultivating a greener future together.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Footer */}
      <footer className="bg-light py-3 text-center border-top">
        <Container>
          <p className="mb-0 text-muted">© 2025 Garden Sync. All rights reserved.</p>
        </Container>
      </footer>
    </div>
  );
};

export default AboutPage;