import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IonIcon } from '@ionic/react';
import { eye, eyeOff } from 'ionicons/icons';
import { AuthContext } from '../context/AuthContext';
import { Container, Form, Button, Card, InputGroup } from 'react-bootstrap';
import NavBar from '../components/NavBar.js';
import './Login.css'; // Keep minimal custom CSS

const Login = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const { token, login } = useContext(AuthContext);
  const navigate = useNavigate();

  const passwordsMatch = password === confirmPassword && password.length > 0;

  useEffect(() => {
    if (token) {
      navigate('/app/dashboard', { replace: true });
    }
  }, [token, navigate]);

  const handleAuth = async (e) => {
    e.preventDefault();
    const endpoint = isRegistering ? '/api/register' : '/api/login';
    const payload = isRegistering
      ? { firstName, lastName, email, password }
      : { email, password };
    try {
      const response = await axios.post(`${endpoint}`, payload);
      if (isRegistering) {
        setError('');
        setIsRegistering(false);
        setFirstName('');
        setLastName('');
        setPassword('');
        setConfirmPassword('');
      } else {
        const { token, firstName } = response.data;
        login(token, firstName);
        setError('');
        navigate('/app/dashboard');
      }
    } catch (err) {
      setError(err.response?.data?.error || `${isRegistering ? 'Registration' : 'Login'} failed`);
    }
  };

  if (token) {
    return null;
  }

  return (
    <div>
      <NavBar/>
    <Container className="my-5">
      <Card className="mx-auto shadow-sm" style={{ maxWidth: '400px' }}>
        <Card.Body>
          <Card.Title className="text-center mb-4">Garden Sync</Card.Title>
          <Form onSubmit={handleAuth}>
            {isRegistering && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
              </>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={isRegistering && confirmPassword ? (passwordsMatch ? 'match' : 'mismatch') : ''}
                />
                <InputGroup.Text onClick={() => setShowPassword(!showPassword)} className="peek-toggle">
                  <IonIcon icon={showPassword ? eyeOff : eye} />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            {isRegistering && (
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={confirmPassword ? (passwordsMatch ? 'match' : 'mismatch') : ''}
                />
              </Form.Group>
            )}
            <Button
              type="submit"
              variant="secondary"
              className="w-100"
              disabled={isRegistering && !passwordsMatch}
            >
              {isRegistering ? 'Register' : 'Login'}
            </Button>
            <p
              className="text-center mt-3 toggle-link cursor-pointer"
              onClick={() => setIsRegistering(!isRegistering)}
            >
              {isRegistering ? navigate("/about") : 'Learn How To Join'}
            </p>
            {error && <p className="text-danger text-center">{error}</p>}
          </Form>
        </Card.Body>
      </Card>
    </Container>
    </div>
  );
};

export default Login;