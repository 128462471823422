import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, Button} from 'react-bootstrap';
import  FullLogo from "../img/FullLogoLargeText.png"
import { AuthContext } from '../context/AuthContext';



const NavBar = () => {
    const { token, logout } = useContext(AuthContext);

    return (
        <Navbar bg="light" expand="lg" className="shadow-sm">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img
                    src={FullLogo}
                    className="d-inline-block align-top"
                    height="60px"
                    width="auto"
                    alt="Garden Sync Logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                    <Nav.Link as={Link} to="/about">About</Nav.Link>
                    <Nav.Link as={Link} to="/app/forum">Forum</Nav.Link>
                    {token ? 
                    <Nav.Link as={Link} to="/app/dashboard" className="btn">
                        Dashboard
                    </Nav.Link> :
                    <Nav.Link as={Link} to="/app" className="btn">
                        Login / Register
                    </Nav.Link>                 
                    }
                    </Nav>
                    {token ? 
                    <Button variant="outline-danger" onClick={() => logout()}>
                        Logout
                    </Button>
                    : null}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar;