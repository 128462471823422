import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { Container, Row, Col, Card, Button, Form, ListGroup, Alert, Tabs, Tab } from 'react-bootstrap';
import NavBar from '../components/NavBar';

const ForumPage = () => {
  const [categories, setCategories] = useState([]);
  const [threads, setThreads] = useState([]);
  const [posts, setPosts] = useState([]);
  const [replies, setReplies] = useState({});
  const [users, setUsers] = useState({}); // Store user info (e.g., firstName) from MongoDB
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedThread, setSelectedThread] = useState(null);
  const [newThreadTitle, setNewThreadTitle] = useState('');
  const [newPostContent, setNewPostContent] = useState('');
  const [newReplyContent, setNewReplyContent] = useState({});
  const [error, setError] = useState('');
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!token) {
      navigate('/app');
    }
  }, [token, navigate]);

  // Fetch categories on mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('api/forum/categories');
        setCategories(response.data);
      } catch (err) {
        setError('Failed to fetch categories');
      }
    };
    fetchCategories();
  }, []);

  // Fetch threads when a category is selected
  useEffect(() => {
    if (selectedCategory) {
      const fetchThreads = async () => {
        try {
          const response = await axios.get('api/forum/threads', {
            params: { category_id: selectedCategory }
          });
          setThreads(response.data);
          setSelectedThread(null); // Reset selected thread when changing categories
          setPosts([]); // Reset posts
          setReplies({}); // Reset replies
        } catch (err) {
          setError('Failed to fetch threads');
        }
      };
      fetchThreads();
    }
  }, [selectedCategory]);

  // Fetch user info from MongoDB
  const fetchUsers = useCallback(async (userIds) => {
    try {
      const newUsers = { ...users };
      for (const userId of userIds) {
        if (!newUsers[userId]) {
          const response = await axios.get(`api/users/${userId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          newUsers[userId] = response.data.firstName;
        }
      }
      setUsers(newUsers);
    } catch (err) {
      setError('Failed to fetch user info');
    }
}, [users, token, setUsers, setError]);

  // Fetch posts when a thread is selected
  useEffect(() => {
    if (selectedThread) {
      const fetchPosts = async () => {
        try {
          const response = await axios.get(`api/forum/threads/${selectedThread}/posts`);
          setPosts(response.data);

          // Fetch user info for all user_ids in posts
          const userIds = [...new Set(response.data.map(post => post.user_id))];
          fetchUsers(userIds);

          // Fetch replies for each post
          const repliesData = {};
          for (const post of response.data) {
            const replyResponse = await axios.get(`api/forum/posts/${post.id}/replies`);
            repliesData[post.id] = replyResponse.data;

            // Fetch user info for reply user_ids
            const replyUserIds = [...new Set(replyResponse.data.map(reply => reply.user_id))];
            fetchUsers(replyUserIds);
          }
          setReplies(repliesData);
        } catch (err) {
          setError('Failed to fetch posts');
        }
      };
      fetchPosts();
    }
  }, [selectedThread, fetchUsers]);

  // Create a new thread
  const handleCreateThread = async (e) => {
    e.preventDefault();
    if (!newThreadTitle) {
      setError('Thread title is required');
      return;
    }
    try {
      const response = await axios.post(
        'api/forum/threads',
        { category_id: selectedCategory, title: newThreadTitle },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setThreads([response.data, ...threads]);
      setNewThreadTitle('');
      setError('');
    } catch (err) {
      setError('Failed to create thread');
    }
  };

  // Create a new post
  const handleCreatePost = async (e) => {
    e.preventDefault();
    if (!newPostContent) {
      setError('Post content is required');
      return;
    }
    try {
      const response = await axios.post(
        'api/forum/posts',
        { thread_id: selectedThread, content: newPostContent },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setPosts([response.data, ...posts]);
      setNewPostContent('');
      setError('');

      // Fetch user info for the new post
      fetchUsers([response.data.user_id]);
    } catch (err) {
      setError('Failed to create post');
    }
  };

  // Create a new reply
  const handleCreateReply = async (postId, e) => {
    e.preventDefault();
    const content = newReplyContent[postId] || '';
    if (!content) {
      setError('Reply content is required');
      return;
    }
    try {
      const response = await axios.post(
        'api/forum/replies',
        { post_id: postId, content },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setReplies({
        ...replies,
        [postId]: [...(replies[postId] || []), response.data]
      });
      setNewReplyContent({ ...newReplyContent, [postId]: '' });
      setError('');

      // Fetch user info for the new reply
      fetchUsers([response.data.user_id]);
    } catch (err) {
      setError('Failed to create reply');
    }
  };

  return (
    <div>
    <NavBar/>
    <Container className="my-5">
      <header className="d-flex justify-content-between align-items-center mb-4">
        <h1>Forum</h1>
      </header>

      {error && <Alert variant="danger">{error}</Alert>}

      <Row>
        <Col md={3}>
          <h4>Categories</h4>
          <ListGroup>
            {categories.map(category => (
              <ListGroup.Item
                key={category.id}
                action
                active={selectedCategory === category.id}
                onClick={() => setSelectedCategory(category.id)}
              >
                {category.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        <Col md={9}>
          {selectedCategory ? (
            <>
              <h4>Threads in {categories.find(c => c.id === selectedCategory)?.name}</h4>
              <Card className="mb-4">
                <Card.Body>
                  <Form onSubmit={handleCreateThread}>
                    <Form.Group className="mb-3">
                      <Form.Label>Create a New Thread</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Thread title"
                        value={newThreadTitle}
                        onChange={(e) => setNewThreadTitle(e.target.value)}
                      />
                    </Form.Group>
                    <Button type="submit" variant="secondary">
                      Create Thread
                    </Button>
                  </Form>
                </Card.Body>
              </Card>

              {threads.length === 0 ? (
                <p>No threads yet. Be the first to start a discussion!</p>
              ) : (
                <ListGroup>
                  {threads.map(thread => (
                    <ListGroup.Item
                      key={thread.id}
                      action
                      onClick={() => setSelectedThread(thread.id)}
                      active={selectedThread === thread.id}
                    >
                      <div className="d-flex justify-content-between">
                        <span>{thread.title}</span>
                        <small className="text-muted">
                          Posted by {users[thread.user_id] || 'Unknown'} on{' '}
                          {new Date(thread.created_at).toLocaleString()}
                        </small>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}

              {selectedThread && (
                <div className="mt-4">
                  <h4>Posts in "{threads.find(t => t.id === selectedThread)?.title}"</h4>
                  <Card className="mb-4">
                    <Card.Body>
                      <Form onSubmit={handleCreatePost}>
                        <Form.Group className="mb-3">
                          <Form.Label>Create a New Post</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Write your post..."
                            value={newPostContent}
                            onChange={(e) => setNewPostContent(e.target.value)}
                          />
                        </Form.Group>
                        <Button type="submit" variant="secondary">
                          Post
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>

                  {posts.length === 0 ? (
                    <p>No posts yet. Be the first to reply!</p>
                  ) : (
                    posts.map(post => (
                      <Card key={post.id} className="mb-3">
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <div>
                              <strong>{users[post.user_id] || 'Unknown'}</strong>
                              <small className="text-muted ms-2">
                                {new Date(post.created_at).toLocaleString()}
                              </small>
                            </div>
                          </div>
                          <p className="mt-2">{post.content}</p>

                          {/* Replies Section */}
                          <Tabs defaultActiveKey="replies" className="mt-3">
                            <Tab eventKey="replies" title={`Replies (${(replies[post.id] || []).length})`}>
                              <ListGroup className="mt-3">
                                {(replies[post.id] || []).map(reply => (
                                  <ListGroup.Item key={reply.id}>
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <strong>{users[reply.user_id] || 'Unknown'}</strong>
                                        <small className="text-muted ms-2">
                                          {new Date(reply.created_at).toLocaleString()}
                                        </small>
                                      </div>
                                    </div>
                                    <p className="mt-2">{reply.content}</p>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>

                              {/* Reply Form */}
                              <Form onSubmit={(e) => handleCreateReply(post.id, e)} className="mt-3">
                                <Form.Group className="mb-3">
                                  <Form.Label>Reply to this post</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Write your reply..."
                                    value={newReplyContent[post.id] || ''}
                                    onChange={(e) =>
                                      setNewReplyContent({
                                        ...newReplyContent,
                                        [post.id]: e.target.value
                                      })
                                    }
                                  />
                                </Form.Group>
                                <Button type="submit" variant="outline-primary">
                                  Reply
                                </Button>
                              </Form>
                            </Tab>
                          </Tabs>
                        </Card.Body>
                      </Card>
                    ))
                  )}
                </div>
              )}
            </>
          ) : (
            <p>Please select a category to view threads.</p>
          )}
        </Col>
      </Row>

      <div className="mt-4">
        <Button variant="outline-primary" onClick={() => navigate('/app/dashboard')}>
          Back to Dashboard
        </Button>
      </div>
    </Container>
    </div>
  );
};

export default ForumPage;