import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, TimeScale, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { AuthContext } from '../context/AuthContext';
import { Container, Button, Alert } from 'react-bootstrap';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, TimeScale, Title, Tooltip, Legend);

const socket = io(process.env.REACT_APP_API_URL);

const DeviceDashboard = () => {
  const { deviceId } = useParams();
  const [deviceData, setDeviceData] = useState([]);
  const [error, setError] = useState('');
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDeviceData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/api/devices/${deviceId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setDeviceData(response.data.deviceData);
      } catch (err) {
        setError(err.response?.data?.error || 'Failed to fetch device data');
      }
    };
    fetchDeviceData();

    socket.emit('authenticate', token);

    socket.on('newDeviceData', (newDevice) => {
      if (newDevice.deviceId === deviceId) {
        setDeviceData((prev) => [newDevice, ...prev.filter(d => d._id !== newDevice._id)]);
      }
    });

    return () => socket.off('newDeviceData');
  }, [deviceId, token]);

  if (error) return <Alert variant="danger">{error}</Alert>;

  const chartDataPoints = deviceData.slice(0, 10).reverse();
  const dataTypes = ['temperature', 'humidity', 'soil_moisture'].filter(type => 
    chartDataPoints.some(d => d.data[type] !== undefined)
  );
  const chartData = {
    labels: chartDataPoints.map(d => new Date(d.timestamp)),
    datasets: dataTypes.map((type, index) => ({
      label: type.charAt(0).toUpperCase() + type.slice(1),
      data: chartDataPoints.map(d => d.data[type] || 0),
      borderColor: [
        '#0288d1',
        '#2e7d32',
        '#ffca28'
      ][index],
      backgroundColor: [
        'rgba(2, 136, 209, 0.2)',
        'rgba(46, 125, 50, 0.2)',
        'rgba(255, 202, 40, 0.2)'
      ][index],
      tension: 0.3
    }))
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute',
          displayFormats: {
            minute: 'HH:mm'
          }
        },
        title: {
          display: true,
          text: 'Time'
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Value'
        }
      }
    },
    plugins: {
      legend: {
        display: true
      }
    }
  };

  return (
    <Container className="my-5">
      <header className="d-flex justify-content-between align-items-center mb-4">
        <h1>Device Dashboard: {deviceId}</h1>
        <Button
          variant="outline-primary"
          onClick={() => navigate('/app/dashboard')}
        >
          Back to Dashboard
        </Button>
      </header>
      <div className="chart-container">
        <h2 className="mb-4">Data Trend (Last 10 Readings)</h2>
        {chartDataPoints.length > 0 ? (
          <Line data={chartData} options={chartOptions} />
        ) : (
          <p>No data to display in chart.</p>
        )}
      </div>
    </Container>
  );
};

export default DeviceDashboard