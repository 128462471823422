import React, { useContext, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import DeviceList from '../components/DeviceList';
import { AuthContext } from '../context/AuthContext';
import { Container, Button} from 'react-bootstrap';
import  NavBar  from '../components/NavBar.js';

const Dashboard = () => {
  const { firstName, token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/app');
    }
  }, [token, navigate]);

  return (
    <div>
    <NavBar/>
    <Container className="my-5">
      <header className="d-flex justify-content-between align-items-center mb-4">
        <h1>Welcome, {firstName}!</h1>
      </header>
      <Button
        variant="secondary"
        className="mb-4"
        onClick={() => navigate('/app/devices/register')}
      >
        Register Device
      </Button>
      <DeviceList />
    </Container>
    </div>
  );
};

export default Dashboard;