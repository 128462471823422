import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Row, Col, Card } from 'react-bootstrap';
import  NavBar  from '../components/NavBar.js';


const LandingPage = () => {
  return (
    <div>
      {/* Header */}
      <NavBar/>
      {/* Hero Section */}
      <section className="bg-light py-5 text-center">
        <Container>
          <h1 className="display-4 mb-4">Welcome to Garden Sync</h1>
          <p className="lead mb-4">Monitor and manage your garden with smart multi-sensor devices.</p>
          <Button as={Link} to="/about" variant='secondary' size="lg">
            Learn More
          </Button>
        </Container>
      </section>

      {/* Features Section */}
      <section className="py-5">
        <Container>
          <h2 className="text-center mb-5">Why Choose Garden Sync?</h2>
          <Row>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Multi-Sensor Support</Card.Title>
                  <Card.Text>
                    Monitor temperature, humidity, and soil moisture with a single device.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Real-Time Data</Card.Title>
                  <Card.Text>
                    Get live updates and visualize your garden's data with interactive charts.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Easy Programming</Card.Title>
                  <Card.Text>
                    Program your devices with a simple drag-and-drop interface using Blockly.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Footer */}
      <footer className="bg-light py-3 text-center border-top">
        <Container>
          <p className="mb-0 text-muted">© 2025 Garden Sync. All rights reserved.</p>
        </Container>
      </footer>
    </div>
  );
};

export default LandingPage;