import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { Container, Form, Button, ListGroup, Alert } from 'react-bootstrap';
import './BlocklyEditor.css';

const defineBlocks = () => {
  Blockly.Blocks['setup_sensor'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('Setup sensor')
        .appendField(new Blockly.FieldDropdown([
          ['Temperature', 'TEMPERATURE'],
          ['Humidity', 'HUMIDITY'],
          ['Soil Moisture', 'SOIL_MOISTURE']
        ]), 'SENSOR_TYPE')
        .appendField('on pin')
        .appendField(new Blockly.FieldTextInput('D2'), 'PIN');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
    }
  };

  Blockly.Blocks['read_sensor'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('Read')
        .appendField(new Blockly.FieldDropdown([
          ['Temperature', 'TEMPERATURE'],
          ['Humidity', 'HUMIDITY'],
          ['Soil Moisture', 'SOIL_MOISTURE']
        ]), 'SENSOR_TYPE')
        .appendField('sensor');
      this.setOutput(true, 'Number');
      this.setColour(160);
    }
  };

  Blockly.Blocks['send_to_server'] = {
    init: function () {
      this.appendValueInput('VALUE')
        .setCheck('Number')
        .appendField('Send');
      this.appendDummyInput()
        .appendField('to server as')
        .appendField(new Blockly.FieldTextInput('data'), 'KEY');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
    }
  };

  Blockly.Blocks['every_seconds'] = {
    init: function () {
      this.appendDummyInput()
        .appendField('Every')
        .appendField(new Blockly.FieldNumber(5), 'SECONDS')
        .appendField('seconds do');
      this.appendStatementInput('DO')
        .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
    }
  };
};

javascriptGenerator.forBlock['setup_sensor'] = function (block) {
  const sensorType = block.getFieldValue('SENSOR_TYPE');
  const pin = block.getFieldValue('PIN');
  let setupCode = '';
  if (sensorType === 'TEMPERATURE' || sensorType === 'HUMIDITY') {
    setupCode = `dht.setup(${pin}, DHTesp::DHT11);\n`;
  } else if (sensorType === 'SOIL_MOISTURE') {
    setupCode = `pinMode(${pin}, INPUT);\n`;
  }
  return setupCode;
};

javascriptGenerator.forBlock['read_sensor'] = function (block) {
  const sensorType = block.getFieldValue('SENSOR_TYPE');
  let readCode = '';
  if (sensorType === 'TEMPERATURE') {
    readCode = 'dht.getTemperature()';
  } else if (sensorType === 'HUMIDITY') {
    readCode = 'dht.getHumidity()';
  } else if (sensorType === 'SOIL_MOISTURE') {
    readCode = 'analogRead(soilPin)';
  }
  return [readCode, javascriptGenerator.ORDER_ATOMIC];
};

javascriptGenerator.forBlock['send_to_server'] = function (block) {
  const value = javascriptGenerator.valueToCode(block, 'VALUE', javascriptGenerator.ORDER_ATOMIC);
  const key = block.getFieldValue('KEY');
  return `data += "${key}:" + String(${value}) + ",";\n`;
};

javascriptGenerator.forBlock['every_seconds'] = function (block) {
  const seconds = block.getFieldValue('SECONDS');
  const statements = javascriptGenerator.statementToCode(block, 'DO');
  return `
    if (millis() - lastUpdate >= ${seconds} * 1000) {
      String data = "";
      ${statements}
      data = data.substring(0, data.length() - 1);
      String topic = "devices/" + String(deviceId) + "/data";
      client.publish(topic.c_str(), data.c_str());
      lastUpdate = millis();
    }\n`;
};

const BlocklyEditor = () => {
  const blocklyDiv = useRef(null);
  const [generatedCode, setGeneratedCode] = useState('');
  const [sensors, setSensors] = useState([]);
  const [currentSensorType, setCurrentSensorType] = useState('');
  const [currentSensorPin, setCurrentSensorPin] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [copySuccess, setCopySuccess] = useState('');
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const location = useLocation();

  useEffect(() => {
    const initialSensors = location.state?.sensors || [];
    setSensors(initialSensors);
  }, [location.state]);

  useEffect(() => {
    defineBlocks();

    const workspace = Blockly.inject(blocklyDiv.current, {
      toolbox: `
        <xml>
          ${sensors.map((sensor, index) => `
            <block type="setup_sensor">
              <field name="SENSOR_TYPE">${sensor.sensorType.toUpperCase()}</field>
              <field name="PIN">${sensor.pin}</field>
            </block>
          `).join('')}
          <block type="every_seconds">
            <statement name="DO">
              ${sensors.map((sensor, index) => `
                <block type="send_to_server">
                  <field name="KEY">${sensor.sensorType}</field>
                  <value name="VALUE">
                    <block type="read_sensor">
                      <field name="SENSOR_TYPE">${sensor.sensorType.toUpperCase()}</field>
                    </block>
                  </value>
                  ${index < sensors.length - 1 ? '<next>' : '</next>'.repeat(sensors.length - 1 - index)}
                </block>
              `).join('')}
            </statement>
          </block>
        </xml>
      `,
    });

    workspace.addChangeListener(() => {
      const code = javascriptGenerator.workspaceToCode(workspace);
      const hasDHT = sensors.some(s => s.sensorType === 'temperature' || s.sensorType === 'humidity');
      const hasSoilMoisture = sensors.some(s => s.sensorType === 'soil_moisture');
      const fullCode = `
#include <ESP8266WiFi.h>
#include <PubSubClient.h>
${hasDHT ? '#include <DHTesp.h>\nDHTesp dht;\n' : ''}
${hasSoilMoisture ? 'int soilPin;\n' : ''}

const char* ssid = "your_wifi_ssid";
const char* password = "your_wifi_password";
const char* mqtt_server = "192.168.x.x";
const int mqtt_port = 1883;
const char* deviceId = "${deviceId}";

WiFiClient espClient;
PubSubClient client(espClient);
unsigned long lastUpdate = 0;

void setup() {
  Serial.begin(115200);
  WiFi.begin(ssid, password);
  while (WiFi.status() != WL_CONNECTED) {
    delay(1000);
    Serial.println("Connecting to WiFi...");
  }
  Serial.println("Connected to WiFi");
  client.setServer(mqtt_server, mqtt_port);
  while (!client.connected()) {
    Serial.println("Connecting to MQTT...");
    if (client.connect("ArduinoClient")) {
      Serial.println("Connected to MQTT");
    } else {
      Serial.print("Failed, rc=");
      Serial.print(client.state());
      delay(2000);
    }
  }
${hasSoilMoisture ? '  soilPin = A0;\n' : ''}${code}}

void loop() {
  if (!client.connected()) {
    reconnect();
  }
  client.loop();
${code}}

void reconnect() {
  while (!client.connected()) {
    Serial.println("Reconnecting to MQTT...");
    if (client.connect("ArduinoClient")) {
      Serial.println("Connected to MQTT");
    } else {
      Serial.print("Failed, rc=");
      Serial.print(client.state());
      delay(2000);
    }
  }
}
      `;
      setGeneratedCode(fullCode);
    });

    return () => workspace.dispose();
  }, [deviceId, sensors]);

  const addSensor = () => {
    if (currentSensorType && currentSensorPin) {
      setSensors([...sensors, { sensorType: currentSensorType, pin: currentSensorPin }]);
      setCurrentSensorType('');
      setCurrentSensorPin('');
    }
  };

  const removeSensor = (index) => {
    setSensors(sensors.filter((_, i) => i !== index));
  };

  const updateSensor = (index, field, value) => {
    const updatedSensors = [...sensors];
    updatedSensors[index] = { ...updatedSensors[index], [field]: value };
    setSensors(updatedSensors);
  };

  const saveSensors = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        process.env.REACT_APP_API_URL + `/api/devices/${deviceId}`,
        { sensors },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSuccess('Sensors updated successfully!');
      setError('');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to update sensors');
      setSuccess('');
    }
  };

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(generatedCode).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    }).catch(() => {
      setCopySuccess('Failed to copy');
    });
  };

  return (
    <Container className="my-5">
      <h3 className="mb-4">Edit Device: {deviceId}</h3>
      <div className="border rounded p-4 bg-light mb-4">
        <h4 className="mb-3">Manage Sensors</h4>
        <Form.Group className="mb-3">
          <Form.Label>Add Sensor</Form.Label>
          <div className="d-flex gap-3 mb-2">
            <Form.Select
              value={currentSensorType}
              onChange={(e) => setCurrentSensorType(e.target.value)}
            >
              <option value="">Select sensor type</option>
              <option value="temperature">Temperature</option>
              <option value="humidity">Humidity</option>
              <option value="soil_moisture">Soil Moisture</option>
            </Form.Select>
            <Form.Control
              type="text"
              value={currentSensorPin}
              onChange={(e) => setCurrentSensorPin(e.target.value)}
              placeholder="Pin (e.g., D2)"
            />
            <Button variant="outline-success" onClick={addSensor}>
              Add Sensor
            </Button>
          </div>
        </Form.Group>
        {sensors.length > 0 && (
          <div className="mb-3">
            <h4 className="mb-3">Current Sensors:</h4>
            <ListGroup>
              {sensors.map((sensor, index) => (
                <ListGroup.Item key={index} className="d-flex gap-3 align-items-center">
                  <Form.Select
                    value={sensor.sensorType}
                    onChange={(e) => updateSensor(index, 'sensorType', e.target.value)}
                    className="w-auto"
                  >
                    <option value="temperature">Temperature</option>
                    <option value="humidity">Humidity</option>
                    <option value="soil_moisture">Soil Moisture</option>
                  </Form.Select>
                  <Form.Control
                    type="text"
                    value={sensor.pin}
                    onChange={(e) => updateSensor(index, 'pin', e.target.value)}
                    placeholder="Pin (e.g., D2)"
                    className="w-auto"
                  />
                  <Button variant="outline-danger" onClick={() => removeSensor(index)}>
                    Remove
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Button variant="primary" className="mt-3 w-100" onClick={saveSensors}>
              Save Sensors
            </Button>
          </div>
        )}
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
      </div>
      <div className="border rounded p-4 bg-light">
        <h4 className="mb-3">Program Device</h4>
        <div ref={blocklyDiv} className="blockly-workspace mb-4" />
        <h4 className="mb-3">Generated Arduino Code:</h4>
        <p>Copy the code below and paste it into the Arduino IDE to upload to your device.</p>
        <Form.Control
          as="textarea"
          value={generatedCode}
          readOnly
          className="code-textarea mb-3"
        />
        <div className="d-flex gap-3">
          <Button variant="success" onClick={copyCodeToClipboard}>
            Copy Code
          </Button>
          <Button variant="secondary" onClick={() => navigate('/app/dashboard')}>
            Back to Dashboard
          </Button>
        </div>
        {copySuccess && <Alert variant="success" className="mt-3">{copySuccess}</Alert>}
      </div>
    </Container>
  );
};

export default BlocklyEditor;